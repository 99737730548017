module.exports = [{
      plugin: require('../../../../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Clickclima Page","short_name":"Clickclima","start_url":"/","theme_color":"#3bbfba","background_color":"#f7f7f7","display":"minimal-ui","icon":"src/images/logo512.png"},
    },{
      plugin: require('../../../../../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
